import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { RiMailLine, RiLockLine } from "react-icons/ri";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import Backdrop from "@mui/material/Backdrop";
import { Modal, Box, Fade } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import db from "../../firebase";
import { onSnapshot, collection } from "firebase/firestore";
import { useEffect } from "react";
import { useData } from "../../contexts/DataContext";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
let Login = (): any => {
  const { setRutInit, setProfile } = useData();
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([
    { name: "Loading...", email: "", pass: "", rut: "", profile: "" },
  ]);
  const [borderEmail, serborderEmail] = useState(
    "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
  );
  const [borderPass, serborderPass] = useState(
    "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
  );
  useEffect(
    () =>
      onSnapshot(collection(db, "users"), (snapshot) =>
        setUsers(
          snapshot.docs.map((doc) => ({
            name: doc.data().name,
            email: doc.data().email,
            pass: doc.data().pass,
            rut: doc.data().rut,
            profile: doc.data().profile,
          }))
        )
      ),
    []
  );

  const handlePassword = () => {
    setShowPass(!showPass);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  const navigate = useNavigate();

  const login = () => {
    email == ""
      ? serborderEmail(
          "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
        )
      : serborderEmail(
          "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
        );

    pass == ""
      ? serborderPass(
          "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
        )
      : serborderPass(
          "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
        );
    if (users.some((e: any) => e.email === email && e.pass === pass)) {
      users.forEach((e, i) => {
        if (e.email === email && e.pass === pass) {
          console.log(e);
          setRutInit(e.rut);
          if ("profile" in e && typeof e.profile === "string") {
            setProfile(e.profile);
          }
          navigate("dashboard", { state: { email, name: e.name } });
        }
      });
    } else {
      serborderPass(
        "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
      );
    }
  };
  return (
    <>
      <div className="bg-white p-8 rounded-lg w-96">
        <div className="mb-10">
          <img className="h-auto max-w-xs" src={logo} />
          <h1 className="text-2xl uppercase font-bold text-center">
            Iniciar Sesión.
          </h1>
        </div>

        <div className="relative">
          <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
            <RiMailLine />
          </div>

          <input
            type="email"
            className={borderEmail}
            placeholder="Correo Electronico"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="relative mt-10">
          <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
            <RiLockLine onClick={handlePassword} />
          </div>
          <input
            type={showPass ? "text" : "password"}
            className={borderPass}
            placeholder="Clave"
            id="clave"
            name="clave"
            onChange={(e) => setPass(e.target.value)}
          />
        </div>

        <div>
          <button
            className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg"
            onClick={login}
          >
            Ingresar
          </button>
        </div>

        <div className="text-left mt-3">
          ¿No tienes una cuenta?{" "}
          <Link
            to="register"
            className="text-sky-600 font-medium hover:underline transition:all"
          >
            Regístrate
          </Link>
        </div>
      </div>
    </>
  );
};
export default Login;
