import React, { useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { RiMailLine, RiLockLine } from "react-icons/ri";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import Backdrop from "@mui/material/Backdrop";
import CSVReader from "react-csv-reader";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "react-dropdown/style.css";
import {
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Card,
  Alert,
  Modal,
} from "react-bootstrap";
import ReactLoading from "react-loading";
import db from "../../firebase";
import { ComponentToPrint } from "./print/index";
import { useReactToPrint } from "react-to-print";
import { detectOverflow } from "@popperjs/core";
import NavbarPag from "../../components/NavbarPag";
import { useData } from "../../contexts/DataContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export type Crypto = {
  name: string;
};
export type order = {
  alto: string;
  ancho: string;
  bultos: string;
  ciudad: string;
  contacto: string;
  destinatario: string;
  direccion: string;
  email: string;
  guia: string;
  kilos: string;
  largo: string;
  observaciones: string;
  fecha: string;
  uuid: number;
};

let Search = (): any => {
  const { rutInit } = useData();
  const navigate = useNavigate();
  useEffect(() => {
    if (rutInit === "") {
      navigate("/");
    }
  }, []);

  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [ordersToday, setOrdersToday] = useState<any[]>([]);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [erroMsg, setErrorMsg] = useState("Faltan datos obligatorios");
  const [loading, setLoading] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [table, setTable] = useState(false);
  const location = useLocation();
  const [cryptos, setCryptos] = useState<Crypto[] | null>([
    { name: "selecciona fecha" },
  ]);
  const [selected, setSelected] = useState<Crypto>();
  const [colDefs, setColDefs] = useState([
    { field: "Id" },
    { field: "Guia" },
    { field: "Destinatario" },
    { field: "Direccion" },
    { field: "Ciudad" },
    { field: "Contacto" },
    { field: "Bultos" },
    { field: "Kilos" },
    { field: "Alto" },
    { field: "Ancho" },
    { field: "Largo" },
    { field: "Observaciones" },
  ]);

  const pdf = () => {
    if (selected !== undefined) {
      //--------------------XLSC--------------------------------
      const data = [
        [
          "GUIA",
          "OD",
          "CLIENTE",
          "CIUDAD",
          "DESTINO",
          "DIRECCION",
          "BULTOS",
          "KILOS",
        ],
      ];
      ordersToday.forEach((e) => {
        // console.log("orders", e);
        data.push([
          e.guia,
          e.uuid,
          e.client,
          e.ciudad,
          e.destinatario,
          e.direccion,
          e.bultos,
          e.kilos,
        ]);
      });
      //console.log(selected.name);
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, selected.name + ".xlsx");
      //------------------PDF------------------------------------
      var doc = new jsPDF("portrait", "px", "a3", false);
      doc.addImage(logo, "PNG", 500, -1, 100, 100);
      doc.setFontSize(20);
      doc.text("MANIFIESTO DE CARGA ", 220, 40);
      doc.setFontSize(17);
      doc.text("CLIENTE:", 10, 80);
      doc.text(location.state.name, 80, 80);
      doc.setFontSize(20);
      doc.text(selected.name, 170, 60);
      doc.text("GUIA", 10, 100);
      doc.text("OD", 80, 100);
      doc.text("CIUDAD", 160, 100);
      doc.text("DESTINO", 230, 100);
      doc.text("DIRECCION", 320, 100);
      doc.text("BULTOS", 450, 100);
      doc.text("KILOS", 550, 100);

      doc.text(
        "____________________________________________________________________________",
        0,
        105
      );
      doc.text(
        "____________________________________________________________________________",
        0,
        720
      );

      let bultosTotal = 0;
      ordersToday.forEach((order: order, index) => {
        bultosTotal += Number(order.bultos);
      });
      ordersToday.forEach((order: order, index) => {
        if (index < 30) {
          doc.text(order.guia, 0, 130 + index * 20);
          doc.text(order.uuid.toString(), 80, 130 + index * 20);
          doc.text(order.ciudad, 160, 130 + index * 20);
          doc.text(order.destinatario, 230, 130 + index * 20);
          doc.text(order.direccion, 320, 130 + index * 20);
          doc.text(order.bultos, 450, 130 + index * 20);
          doc.text(order.kilos, 550, 130 + index * 20);
        } else {
          if (index % 30 === 0 && index !== 0) {
            doc.text("Total Bultos : " + bultosTotal, 10, 740);
            doc.text("_______________________", 40, 800);
            doc.text("_______________________", 380, 800);
            doc.text("Persona que genera manifiesto", 38, 820);
            doc.text("Persona que transporta carga", 380, 820);
            doc.addPage();
            //lo mismo
            doc.addImage(logo, "PNG", 500, -1, 100, 100);
            doc.setFontSize(20);
            doc.text("MANIFIESTO DE CARGA ", 220, 40);
            doc.setFontSize(17);
            doc.text("CLIENTE:", 10, 80);
            doc.text(location.state.name, 80, 80);
            doc.setFontSize(20);
            doc.text(selected.name, 170, 60);
            doc.text("GUIA", 10, 100);
            doc.text("OD", 80, 100);
            doc.text("CIUDAD", 160, 100);
            doc.text("DESTINO", 230, 100);
            doc.text("DIRECCION", 320, 100);
            doc.text("BULTOS", 450, 100);
            doc.text("KILOS", 550, 100);

            doc.text(
              "____________________________________________________________________________",
              0,
              105
            );
            doc.text(
              "____________________________________________________________________________",
              0,
              720
            );
            //
            doc.text("Total Bultos : " + bultosTotal, 10, 740);
            doc.text("_______________________", 40, 800);
            doc.text("_______________________", 380, 800);
            doc.text("Persona que genera manifiesto", 38, 820);
            doc.text("Persona que transporta carga", 380, 820);
          }

          doc.text(order.guia, 0, 130 + (index % 30) * 20);
          doc.text(order.uuid.toString(), 80, 130 + (index % 30) * 20);
          doc.text(order.ciudad, 160, 130 + (index % 30) * 20);
          doc.text(order.destinatario, 230, 130 + (index % 30) * 20);
          doc.text(order.direccion, 320, 130 + (index % 30) * 20);
          doc.text(order.bultos, 450, 130 + (index % 30) * 20);
          doc.text(order.kilos, 550, 130 + (index % 30) * 20);
        }
      });
      if (ordersToday.length < 30) {
        doc.text("Total Bultos : " + bultosTotal, 10, 740);
        doc.text("_______________________", 40, 800);
        doc.text("_______________________", 380, 800);
        doc.text("Persona que genera manifiesto", 38, 820);
        doc.text("Persona que transporta carga", 380, 820);
      }
      doc.save(selected.name + ".pdf");
    }
  };
  const loadOrders = async () => {
    //console.log(selected?.name);
    if (selected?.name === undefined) {
      return;
    }
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setMilliseconds(0);
    today.setSeconds(0);
    let arrayAux: any[] = [];
    let arrayAuxToday: any[] = [];

    const q = query(
      collection(db, "orders"),
      where("email", "==", location.state.email),
      where("fecha", "==", selected?.name)
    );
    const qt = query(
      collection(db, "orders"),
      where("email", "==", location.state.email),
      where("time", ">=", today)
    );
    const querySnapshot = await getDocs(q);
    const querySnapshotoday = await getDocs(qt);
    const infoToday: any = [];

    const info: any = [];
    querySnapshot.forEach((doc) => {
      info.push({ data: doc.data() });
    });
    querySnapshotoday.forEach((doc) => {
      infoToday.push({ data: doc.data() });
    });
    //console.log(info);
    for (let i = 0; i < info.length; i++) {
      arrayAux.push({ ...info[i].data, client: location.state.name });
    }
    for (let i = 0; i < infoToday.length; i++) {
      arrayAuxToday.push({ ...infoToday[i].data, client: location.state.name });
    }
    /*
    info.map((doc: any) => {
      
    });*/
    //console.log("aux", arrayAux);
    arrayAux.sort((a, b) => (a.id > b.id ? 1 : -1));
    arrayAuxToday.sort((a, b) => (a.id > b.id ? 1 : -1));

    //console.log("arrayAux:", arrayAux);
    setOrders(arrayAux);
    setOrdersToday(arrayAuxToday);

    //setOrders(info);
    /*
    onSnapshot(
      query(
        collection(db, "orders"),
        where("email", "==", location.state.email),
        where("fecha", "==", selected?.name)
      ),
      (snapshot) => {
        snapshot.docs.map((doc) => {
          arrayAux.push({ ...doc.data(), client: location.state.name });
        });
        console.log("aux", arrayAux);
        arrayAux.sort((a, b) => (a.id > b.id ? 1 : -1));
        //console.log("arrayAux:", arrayAux);
        setOrders(arrayAux);
      }
    );*/
  };
  /*
  onSnapshot(
    query(
      collection(db, "ordersxusers"),
      where("email", "==", location.state.email)
    ),
    (snapshot) => {
      let auxarray: Crypto[] = [{ name: "selecciona fecha" }];
      snapshot.docs.map((doc) =>
        auxarray.push({
          name: doc.data().fecha,
        })
      );
      setCryptos(auxarray);
    }
  );*/
  useEffect(() => {
    const getDate = async () => {
      const q = query(
        collection(db, "ordersxusers"),
        where("email", "==", location.state.email)
      );
      const querySnapshot = await getDocs(q);
      let auxarray: Crypto[] = [{ name: "selecciona fecha" }];
      querySnapshot.forEach((doc) => {
        auxarray.push({ name: doc.data().fecha });
      });
      //console.log(auxarray);
      setCryptos(auxarray);
    };
    getDate();
  }, []);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  const [loadings, setLoadings] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const handleAfterPrint = React.useCallback(() => {
    //console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    //console.log("`onBeforePrint` called");
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handleOnBeforeGetContent = React.useCallback(() => {
    //console.log("`onBeforeGetContent` called");
    setLoadings(true);
    setText("Loading new text...");

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoadings(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoadings, setText]);
  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function" &&
      (onBeforeGetContentResolve !== null ||
        onBeforeGetContentResolve["current"] !== null)
    ) {
      const aux: any = onBeforeGetContentResolve["current"];
      aux();
    }
  }, [onBeforeGetContentResolve.current, text]);
  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    if (rutInit === "") {
      return <div></div>;
    }
    return (
      <button
        className=" bg-sky-600 text-white py-2 px-4 rounded-lg w-32 "
        style={{ marginLeft: 600 }}
      >
        Imprimir
      </button>
    );
  }, []);

  return (
    <div>
      <div className="w-screen h-screen bg-[#FFFFFF] overflow-hidden">
        {/* navbar */}
        <NavbarPag />
        <div className="h grid grid-cols-6  content-start mt-10">
          <div></div>
          <div>Selecciona la fecha y hora de carga csv </div>
          <div>
            <select
              onChange={(e) => {
                const c = cryptos?.find(
                  (x) => x.name === e.target.value
                ) as Crypto;
                setSelected(c);
              }}
              defaultValue="default"
            >
              {cryptos
                ? cryptos.map((crypto, index) => {
                    return (
                      <option key={index} value={crypto.name}>
                        {crypto.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="pl-5">
            <button
              className=" bg-sky-600 text-white py-2 px-3 rounded-lg"
              onClick={loadOrders}
            >
              Cargar Etiquetas
            </button>
          </div>
          <div>
            {orders.length > 1 && (
              <button
                className=" bg-sky-600 text-white py-2 px-4 rounded-lg"
                onClick={pdf}
              >
                Descargar manifiesto
              </button>
            )}
          </div>
        </div>
        <div className="h grid grid-cols-1  content-start mt-10">
          {orders.length > 1 && componentRef && (
            <>
              {" "}
              <ReactToPrint
                content={reactToPrintContent}
                documentTitle="AwesomeFileName"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={reactToPrintTrigger}
              />
              {loadings && <p className="indicator ml-6">Loading...</p>}
              <ComponentToPrint {...orders} ref={componentRef} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Search;
