import { useEffect, useState } from "react";
import NavbarPag from "../../components/NavbarPag";
import db from "../../firebase";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { useData } from "../../contexts/DataContext";
import { useNavigate } from "react-router-dom";
interface Data {
  doc: string;
  data: {};
}
const RegisterUser = () => {
  const { rutInit } = useData();
  const navigate = useNavigate();
  useEffect(() => {
    if (rutInit === "") {
      navigate("/");
    }
  }, []);
  const [odValue, setOdValue] = useState("");
  const [create, setCreate] = useState(false);

  const addInfo = async (data: Data) => {
    try {
      await addDoc(collection(db, data.doc), { ...data.data });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    if (create) {
      const timer = setTimeout(() => {
        setCreate(false);
      }, 3000); // Cambiar a false después de 3 segundos
      return () => clearTimeout(timer);
    }
  }, [create]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOdValue(event.target.value);
  };
  const search = async () => {
    if (odValue === "") {
      return;
    }
    const res = await addInfo({ doc: "emailDriver", data: { email: odValue } });
    if (res) {
      setOdValue("");
      setCreate(res);
    }
  };
  if (rutInit === "") {
    return <div></div>;
  }
  return (
    <div className="w-screen h-screen">
      {" "}
      <NavbarPag />
      <div className=" h-full bg-neutral-200 overflow-hidden flex flex-col items-center">
        <div className=" w-11/12">
          <h2 className="text-2xl text-center p-3">Registrar Usuario</h2>

          <article className=" bg-slate-50">
            <div className=" bg-neutral-300 p-2 text-sky-600 mt-4">
              INGRESE EMAIL
            </div>
            <div className="flex p-4">
              <input
                type="text"
                value={odValue}
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-64 mr-4"
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={search}
              >
                Agregar
              </button>
            </div>
          </article>
          {create ? (
            <article className=" p-4 text-center">
              <div className="text-3xl">
                El usuario se ha creado satisfactoriamente{" "}
              </div>
            </article>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
