import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  RiMailLine,
  RiLockLine,
  RiBuilding4Line,
  RiArrowLeftSLine,
} from "react-icons/ri";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import db from "../../firebase";
import {
  onSnapshot,
  collection,
  addDoc,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useEffect } from "react";
import ReactLoading from "react-loading";

let Register = (): any => {
  const [showPass, setShowPass] = useState(false);
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [borderName, serborderName] = useState(
    "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
  );
  const [borderEmail, serborderEmail] = useState(
    "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
  );
  const [borderRut, serborderRut] = useState(
    "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
  );
  const [borderPass, serborderPass] = useState(
    "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
  );
  const handleNew = async () => {
    const collectionRef = collection(db, "users");

    const payload = { email, name, pass: pass1, rut };
    const collectionRef2 = collection(db, "listClient");
    const collectionRef3 = collection(db, "listClientNumberOrder");
    const payload2 = { email, name, time: Date.now() };
    const payload3 = { email, numero: 0 };
    if (!errorEmail) {
      const docRef = await addDoc(collectionRef, payload);
      const docRef2 = await addDoc(collectionRef2, payload2);
      const docRef3 = await addDoc(collectionRef3, payload3);
      const TimeRef = doc(db, "timeClien", "1");
      updateDoc(TimeRef, { id: 0, time: Date.now() });
      setLoading(false);
      setEmail("");
      setPass1("");
      setName("");
      setRut("");
      await goBack();
    } else {
      setLoading(false);
    }
  };
  const handlePassword = () => {
    setShowPass(!showPass);
  };
  const navigate = useNavigate();
  const goBack = async () => {
    await navigate(-1);
  };
  /*
  const handleNew = async () => {
    const collectionRef = collection(db, "users");
    const payload = { email, name, pass: pass1, rut };
    const docRef = await addDoc(collectionRef, payload);
    setLoading(false);
    goBack();
  }; */
  const handleSubmit = async (e: any) => {
    name == ""
      ? serborderName(
          "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
        )
      : serborderName(
          "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
        );
    rut == ""
      ? serborderRut(
          "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
        )
      : serborderRut(
          "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
        );

    email == ""
      ? serborderEmail(
          "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
        )
      : serborderEmail(
          "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
        );

    pass1 == "" || pass2 == ""
      ? serborderPass(
          "w-full border border-red-200 outline-none py-2 px-8 rounded-lg"
        )
      : serborderPass(
          "w-full border border-gray-200 outline-none py-2 px-8 rounded-lg"
        );

    if (rut !== "" && name !== "" && pass1 !== "" && pass2 !== "") {
      if (pass1 !== pass2) {
        setError(true);
        console.log("if");
      } else {
        setLoading(true);
        //handleNew();
      }
    }
  };
  useEffect(() => {
    const getDate = async () => {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let auxarray: any = [];
      querySnapshot.forEach((doc) => {
        auxarray.push({ data: doc.data() });
      });
      //console.log("email::", auxarray.length);
      if (auxarray.length != 0) {
        setErrorEmail(true);
      } else {
        setErrorEmail(false);
      }
    };
    getDate();
    /*onSnapshot(
      query(collection(db, "users"), where("email", "==", email)),
      (snapshot) => {
        if (!snapshot.empty) {
          setErrorEmail(true);
        } else {
          setErrorEmail(false);
        }
      }
    );*/
  }, [email]);
  useEffect(() => {
    // Return early, if this is the first render:
    if (loading && rut !== "" && name !== "" && pass1 !== "" && pass2 !== "") {
      handleNew();
    }
  }, [loading]);
  return (
    <div className="bg-white p-8 rounded-lg w-96">
      <div className="mb-10">
        <RiArrowLeftSLine onClick={goBack} />

        <img className="h-auto max-w-xs" src={logo} />
        <h1 className="text-2xl uppercase font-bold text-center">
          Registro de Clientes
        </h1>
      </div>
      <div className="relative mb-10">
        <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
          <RiBuilding4Line />
        </div>

        <input
          type="text"
          className={borderName}
          placeholder="Nombre"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="relative mb-10">
        <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
          <RiBuilding4Line />
        </div>

        <input
          type="text"
          className={borderRut}
          placeholder="Rut Empresa"
          onChange={(e) => setRut(e.target.value)}
        />
      </div>
      <div className="relative mb-10">
        <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
          <RiMailLine />
        </div>

        <input
          type="email"
          className={borderEmail}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo Electronico"
        />
      </div>
      <div className="relative mb-10">
        <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
          <RiLockLine onClick={handlePassword} />
        </div>
        <input
          type={showPass ? "text" : "password"}
          className={borderPass}
          onChange={(e) => setPass1(e.target.value)}
          placeholder="Clave"
        />
      </div>
      <div className="relative mb-5">
        <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-500">
          <RiLockLine onClick={handlePassword} />
        </div>
        <input
          type={showPass ? "text" : "password"}
          className={borderPass}
          onChange={(e) => setPass2(e.target.value)}
          placeholder="Repetir clave"
        />
      </div>

      <div>
        {error && (
          <p className=" font-bold text-red-600 text-left mt-5">
            Pass no coinciden !!
          </p>
        )}
        {errorEmail && (
          <p className=" font-bold text-red-600 text-left mt-5">
            Correo incorrecto !!
          </p>
        )}

        {loading && (
          <div style={{ paddingLeft: "43%" }}>
            <ReactLoading
              type={"spin"}
              color={"blue"}
              height={"20%"}
              width={"20%"}
            />
          </div>
        )}

        <button
          className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg"
          onClick={handleSubmit}
        >
          Registrar
        </button>
      </div>
    </div>
  );
};
export default Register;
