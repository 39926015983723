import React from "react";

import { Outlet } from "react-router-dom";
let Auth = (): any => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Outlet />
    </div>
  );
};

export default Auth;
