import { Card, Typography } from "@material-tailwind/react";
import { useData } from "../contexts/DataContext";
import { useNavigate } from "react-router-dom";
import { Outlet, useLocation } from "react-router-dom";
const TABLE_HEAD = ["Od", "Hora", "Codigo", ""];

interface TableRow {
  Od: string;
  Hora: string;
  Codigo: string;
}

interface DefaultTableProps {
  tableRows: TableRow[];
}
export function DefaultTable({ tableRows }: DefaultTableProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOd } = useData();
  const search = (od: string) => {
    setOd(od);
    navigate("/searchOD", {
      state: {
        email: location.state.email,
        name: location.state.name,
      },
    });
  };

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map(({ Od, Hora, Codigo }, index) => {
            const isLast = index === tableRows.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={Od}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {Od}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {Hora}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {Codigo}
                  </Typography>
                </td>
                <td className={classes}>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      search(Od);
                    }}
                  >
                    VER MAS
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}
