import React, { useEffect, useState } from "react";
import MyGoogleMap from "../../components/MyGoogleMap";
import NavbarPag from "../../components/NavbarPag";
import { Any } from "react-spring";
import db from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useData } from "../../contexts/DataContext";
import { useNavigate } from "react-router-dom";

interface Data {
  doc: string;
  field: string;
  where: number;
}
const SearchOd = () => {
  const { rutInit, od } = useData();

  const navigate = useNavigate();
  useEffect(() => {
    if (rutInit === "") {
      navigate("/");
    }
  }, []);

  let lisState = ["INICIAL", "RETIRADO", "ENTREGADO", "NO ENTREGADO"];
  const [state, setState] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [nota, setNota] = useState("");
  let [imgSrc, setImgSrc] = useState("");
  let [photo, setPhoto] = useState(0);
  const [imageSrc, setImageSrc] = useState("");
  const [cordenadas, setCordenadas] = useState({
    lat: 0,
    lng: 0,
  });
  const [rut, setRut] = useState("");
  const [errorOd, setErrorOd] = useState(true);
  const getWhere = async (data: Data) => {
    console.log("getWhere", data.where);
    try {
      const q = query(
        collection(db, data.doc),
        where(data.field, "==", data.where)
      );
      const querySnapshot = await getDocs(q);
      const info: any = [];
      querySnapshot.forEach((doc) => {
        info.push({ id: doc.id, data: doc.data() });
      });
      const res = { error: false, data: info };
      return res;
    } catch {
      const res = { error: false, data: [] };
      res.error = true;
      return res;
    }
  };
  const [odValue, setOdValue] = useState(od);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOdValue(event.target.value);
  };
  const search = async () => {
    //event.preventDefault();
    console.log(odValue);
    const orders = await getWhere({
      doc: "orders",
      field: "uuid",
      where: Number(odValue),
    });
    const deliveries = await getWhere({
      doc: "deliveries",
      field: "uuid",
      where: Number(odValue),
    });

    if (deliveries.data.length === 0) {
      setErrorOd(true);
    } else {
      //console.log("orders:", orders.data[0].data);
      //console.log("deliveries", deliveries.data[0].data);
      const data1 = orders.data[0].data;
      const data2 = deliveries.data[0].data;
      let stateD = deliveries.data[0].data.state;
      const lis1 = Object.keys(data1);
      const lis2 = Object.keys(data2);
      setErrorOd(false);
      setState(lisState[stateD]);
      //console.log("lis1", lis1);
      //console.log("lis2", lis2);
      setName(lis1.includes("name") ? data1.name : "");
      setCordenadas(
        lis1.includes("latitude")
          ? {
              lat: Number(data1.latitude),
              lng: Number(data1.longitude),
            }
          : {
              lat: 0,
              lng: 0,
            }
      );
      setRut(lis1.includes("latitude") ? data1.rut : "");
      if (lis1.includes("date")) {
        const fecha = new Date(data1.date);
        const dia = fecha.getDate();
        const mes = fecha.getMonth() + 1;
        const year = fecha.getFullYear();
        const hours = fecha.getHours();
        const minnute = fecha.getMinutes();
        setDate(
          `${dia < 10 ? "0" + dia : dia}/${mes < 10 ? "0" + mes : mes}/${year} Hora ${hours < 10 ? "0" + hours : hours}:${minnute < 10 ? "0" + minnute : minnute}`
        );
      }
      setNota(lis1.includes("comment") ? data1.comment : "");
      if (lis1.includes("photo")) {
        setPhoto(data1.photo.length);
        console.log("photo:", photo);
        setImageSrc(`data:image/jpeg;base64,${data1.photo}`);
      } else {
        setImageSrc("");
      }
    }
  };
  useEffect(() => {
    if (od !== "") {
      console.log("od:", od);
      setOdValue(od);
      search();
    }
  }, []);
  if (rutInit === "") {
    return <div></div>;
  }
  return (
    <div className={errorOd ? "w-screen h-screen" : ""}>
      {/* navbar */}
      <NavbarPag />
      <div className=" h-full bg-neutral-200 overflow-hidden flex flex-col items-center">
        <div className=" w-11/12">
          <h2 className="text-2xl text-center p-3">Consultar OD</h2>
          <div className="bg-sky-600 p-2 text-white">
            Ingresa el Od de la orden
          </div>
          <article className=" bg-slate-50">
            <div className=" bg-neutral-300 p-2 text-sky-600 mt-4">
              INGRESE EL #OD DE BUSQUEDA
            </div>
            <div className="flex p-4">
              <input
                type="text"
                value={odValue} // Asignar el valor del estado al input
                onChange={handleInputChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-64 mr-4"
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={search}
              >
                Buscar
              </button>
            </div>
          </article>
          <div className=" m-2 p-3">
            {errorOd ? (
              <div className="text-2xl text-sky-700">
                NO EXISTE INFORMACION DE LA ORDEN
              </div>
            ) : (
              ""
            )}
          </div>
          {!errorOd ? (
            <article className=" bg-slate-50">
              <div className=" bg-neutral-300 p-2 text-gray-700 mt-4">
                ESTADO ACTUAL - <span className="text-green-500">{state}</span>
                (NORMAL)
              </div>
              <div className="flex p-3">
                <div className=" w-1/3">
                  <div>
                    <span className=" text-sky-700">Nombre receptor:</span>
                    {name}
                  </div>
                  <div>
                    <span className=" text-sky-700">Estado:</span>
                    {state}
                  </div>
                  <div>
                    <span className=" text-sky-700">
                      Cordenadas de destino:
                    </span>
                    {cordenadas.lat},{cordenadas.lng}
                  </div>
                </div>
                <div className=" w-1/3">
                  <div>
                    <span className=" text-sky-700"> Rut receptor:</span>
                    {rut}
                  </div>
                  <div>
                    <span className=" text-sky-700">Fecha gestion:</span>
                    {date}
                  </div>
                  <div>
                    <span className=" text-sky-700">Nota:</span>
                    {nota}
                  </div>
                </div>
                <div className=" w-1/3">
                  <div>
                    <span className=" text-sky-700"> Codigo biometrico:</span>
                    False
                  </div>
                  <div>
                    <span className=" text-sky-700"> Tipo certificación:</span>
                    Normal
                  </div>
                </div>
              </div>
            </article>
          ) : (
            ""
          )}
          {!errorOd ? (
            <article className="flex p-5">
              <div className=" w-1/2 p-3 ">
                <div className=" w  bg-neutral-300">
                  <div className=" text-sky-700 p-1 pl-3">Punto Destino</div>
                  <div>
                    {cordenadas.lat !== 0 && cordenadas.lng !== 0 ? (
                      <MyGoogleMap center={cordenadas} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className=" w-1/2 p-3 ">
                <div className=" w  bg-neutral-300">
                  <div className=" text-sky-700 p-1 pl-3">
                    Evidencia de gestion
                  </div>
                  {photo > 0 ? (
                    <img
                      className=" ml-auto mr-auto"
                      src={imageSrc}
                      alt="Imagen en Base64"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </article>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchOd;
